<!--
 * @Author: LiuXin
 * @Date: 2021-08-20 10:52:59
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-08-23 14:07:51
-->
<template>
  <svg :class="svgClass" aria-hidden="true" v-bind="$attrs" :style="svgStyle">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    svgStyle() {
      let style = { width: '14px', height: '14px' };
      if (this.$attrs.style?.['font-size']) {
        style = { width: this.$attrs.style['font-size'], height: this.$attrs.style['font-size'] };
      }
      return style;
    },
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    },
  },
};
</script>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>