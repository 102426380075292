<!--
 * @Author: chenxing
 * @Date: 2021-03-03 16:04:12
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-09-01 11:03:00
-->
<template>
  <a-config-provider :locale="locale" :get-popup-container="getPopupContainer">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </a-config-provider>
</template>
<script>
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
    return {
      locale,
    };
  },
  methods: {
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    },
  },
  created() {
    this.$router.afterEach(to => {
      this.$store.commit('SET_ROUTERTAGS', to);
    });
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body,
#app,
.ant-layout {
  min-height: 100vh;
}
</style>
