/*
 * @Author: Yran
 * @Date: 2021-08-23 10:31:40
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-01 10:21:30
 */

const directive = {
    // 当被绑定的元素插入到 DOM 中时……
    mounted: (el, binding, vnode) => {
        const value = binding.value;
        const auths = localStorage.getItem('funcPoint') || '';
        if (!auths.split(',').includes(value)) {
            el.parentNode?.removeChild(el);
        }
    }
};

export default directive;