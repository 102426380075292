/*
 * @Author: LiuXin
 * @Date: 2021-08-20 11:36:42
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-08-23 13:36:35
 */

const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context('./svg', false, /\.svg$/);
requireAll(req);
