/*
 * @Author: chenxing
 * @Date: 2021-03-30 23:04:03
 * @LastEditors: chenxing
 * @LastEditTime: 2021-06-08 19:22:35
 */
let Layout = () => import('@/components/Layout');
const routes = [
    {
        path: '/user',
        component: Layout,
        redirect: '/user/index',
        meta: {},
        children: [{
            path: '/user/index',
            name: 'user',
            meta: {},
            component: () => import('@/views/user')
        }]
    }
];

export default routes;