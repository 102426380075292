/*
 * @Author: chenxing
 * @Date: 2021-03-05 14:11:00
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-12 15:54:26
 */

/* eslint-disable */

import { createStore } from 'vuex';
import { uuid } from 'vue-uuid';

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', false, /\.js$/);
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

// Create a new store instance.
const store = createStore({
  state() {
    return {
      serverErrorMessage: '',
      config: {
        sideMenuCollapse: false,
      },
      routerTags: [],
      currentTag: {},
      globalEvent: { event: '', args: null, key: '' },
      userChannel: '', //主页登录人员所属渠道
      // 主页下拉数据（部门、运营人员、项目）
      dorpDownData: {
        deptList: [],
        operatorList: [],
        projectList: [],
      },
    };
  },
  mutations: {
    SET_SERVERERRORMESSAGE(state, message) {
      state.serverErrorMessage = message;
    },
    SET_SIDEMENUCOLLAPSE(state, value) {
      state.config.sideMenuCollapse = value;
    },
    SET_CURRENTTAG(state, tag) {
      state.currentTag = tag;
    },
    // 添加页面顶部路由tag
    SET_ROUTERTAGS(state, tag) {
      const index = state.routerTags.findIndex(item => {
        return item.path === tag.path;
      });
      if (index === -1 && !tag.hideTags) {
        state.routerTags.unshift(tag);
      }
    },
    // 删除页面顶部路由tag
    REMOVE_ROUTERTAGS(state, data) {
      if (data.clear) {
        state.routerTags = [];
        state.currentTag = {};
        return;
      }
      const index = state.routerTags.findIndex(item => {
        return item.href === data.tag.href;
      });
      state.routerTags.splice(index, 1);
    },
    // 设置全局事件
    SET_GLOBALEVENT(state, data) {
      state.globalEvent.event = data.event;
      state.globalEvent.args = data.args;
      state.globalEvent.key = uuid.v1();
    },
    // 设置登录人员所属渠道
    SET_USERCHANNEL(state, channel) {
      state.userChannel = channel;
    },
    // 设置主页下拉数据
    SET_HOMEDROPDOWNDATA(state, dorpDownData) {
      Object.keys(state.dorpDownData).forEach(key => {
        state.dorpDownData[key].splice(0, state.dorpDownData[key].length, ...dorpDownData[key]);
      });
    },
  },
  actions: {},
  modules,
});

export default store;
